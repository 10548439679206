import React, { MutableRefObject } from 'react';

const createCursorElement = ({
  container,
  elem,
  size,
  x,
  y,
}: {
  container: HTMLDivElement;
  elem: HTMLDivElement;
  size: number;
  x: number;
  y: number;
}) => {
  // Randomize the color from a set of options
  const colors = [
    '#C7E5F4',
    '#FAEAFF',
    '#E6F4F1',
    '#F0FBFF',
    '#A8F0FA',
    '#B0D7FF',
    '#FAF8FF',
    '#E9D9FE',
    '#FBF4D7',
    '#C3FCF1',
    '#F2FEDC',
    '#C5D2B0',
    '#FFD591',
    '#D8D5DB',
    '#D3C0CD',
  ];
  // Set styles including random color and size
  const color = colors[Math.floor(Math.random() * colors.length)];
  const randomOffset1 = Math.random() * 30;
  const randomOffset2 = Math.random() * 30;

  elem.style.backgroundColor = color;
  elem.style.width = `${size}px`;
  elem.style.height = `${size}px`;
  elem.style.left = `${x + randomOffset1}px`;
  elem.style.top = `${y + randomOffset2}px`;
  // Random opacity from 0.5 to 1.0
  elem.style.opacity = String(0.5 + Math.random() * 0.5);

  setTimeout(() => {
    elem.style.transform = 'translate(-50%, -50%) scale(2)';
    elem.style.opacity = '0';
  }, 50);

  setTimeout(() => {
    container.removeChild(elem);
  }, 600);
};

export const interpolateAndCreate = (
  x: number,
  y: number,
  container: HTMLDivElement,
  lastPositionRef: MutableRefObject<{ x: number; y: number } | null>
) => {
  if (!lastPositionRef) return;
  const lastPos = lastPositionRef.current;
  if (lastPos) {
    const distance = Math.sqrt(
      Math.pow(x - lastPos.x, 2) + Math.pow(y - lastPos.y, 2)
    );
    // step
    const step = 5 + Math.random() * 15;
    // pixels per step
    const steps = distance / step;
    for (let i = 1; i <= steps; i++) {
      const newX = lastPos.x + ((x - lastPos.x) * i) / steps;
      const newY = lastPos.y + ((y - lastPos.y) * i) / steps;
      createStar(newX, newY, container);
    }
  }
  // Update last position
  lastPositionRef.current = { x, y };
};

export const createStar = (x: number, y: number, container: HTMLDivElement) => {
  const randomBit = Math.round(Math.random());
  const isStar = randomBit === 0;

  // Randomize the size between 5px and 15px
  const size = isStar ? 3 + Math.random() * 10 : 0.5 + Math.random() * 2;
  // Randomize the offset between 5px and 15px

  const elem = document.createElement('div');
  elem.className = isStar ? 'star' : 'circle';
  createCursorElement({ container, elem, size, x, y });
  container.appendChild(elem);
};
