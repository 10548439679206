import { Box } from '@mui/system';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SERVER_URL } from '../const/MacroConst';
import DOMPurify from 'dompurify';
import '../css/ckeditor/content-styles.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Typography } from '@mui/material';

const DetailedProjectPage = () => {
  return (
    <Box
      sx={{
        'width': '100vw',
        'maxWidth': '100vw',
        '.body': {
          maxWidth: '100%', // Ensures content does not exceed the width of its container
          width: 'auto', // Overrides any explicit width settings in the HTML
          boxSizing: 'border-box', // Ensures padding and borders are included in the width calculation
        },
      }}
    >
      <DetailedProject />
    </Box>
  );
};

const DetailedProject = () => {
  const { title } = useParams();
  const [content, setContent] = useState('');
  const [time, setTime] = useState('');
  const contentRef = useRef<HTMLDivElement>(null);

  const fetchProjectData = async (title: string) => {
    const titleSlug = title.toLowerCase().replace(/\s+/g, '-');
    const response = await fetch(
      `${SERVER_URL}/api/detailed_project/${titleSlug}/`
    );
    const data = await response.json();
    const transformedContent = data.content.replace(
      /width="[^"]*"/g,
      'style="max-width: 100%;"'
    );
    // Satinize the html
    const cleanContent = DOMPurify.sanitize(transformedContent);
    setContent(cleanContent);
    setTime(data.time);
  };

  // fetch detailed project data
  useEffect(() => {
    if (title) {
      fetchProjectData(title);
    }
  }, [title]);

  useEffect(() => {
    if (contentRef.current) {
      // Add aos animation to all figures
      const figures = contentRef.current.querySelectorAll('figure');
      figures.forEach((figure: HTMLElement) => {
        figure.setAttribute('data-aos', 'fade-zoom-in');
        figure.setAttribute('data-aos-easing', 'ease-out-cubic');
        figure.setAttribute('data-aos-duration', '500');
        figure.setAttribute('data-aos-offset', '0');
      });
      const paragraphs = contentRef.current.querySelectorAll('p');
      paragraphs.forEach((p: HTMLElement) => {
        // Add 'empty-line' to all empty paragraph
        if (
          p.innerHTML === '&nbsp;' ||
          (p.textContent && p.textContent.trim() === '')
        ) {
          p.setAttribute('class', 'empty-line');
        } else {
          // Add data-aos zoom-in animation to other paragraphs
          p.setAttribute('data-aos', 'fade-zoom-in');
          p.setAttribute('data-aos-easing', 'ease-out-cubic');
          p.setAttribute('data-aos-duration', '500');
          p.setAttribute('data-aos-offset', '0');
        }
      });
      AOS.refresh(); // Refresh AOS to apply animations to new DOM elements
    }
  }, [content]); // This effect runs every time the content state changes

  return (
    <Box sx={{ margin: '5%' }}>
      <Typography
        variant='h3'
        fontFamily='Georgia, serif'
        sx={{
          textShadow:
            '0 0 7px #fff, 0 0 10px rgb(3, 180, 255), 0 0 18px rgb(87, 4, 220),0 0 30px rgb(138, 74, 200)',
          marginTop: { xs: '15%', sm: 0 }, // 20px margin-top on xs devices, none on sm and above
        }}
      >
        {title}
      </Typography>
      <Typography fontFamily='URW Chancery L, cursive'>{time}</Typography>
      <Box
        ref={contentRef}
        className='ck-content'
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </Box>
  );
};

export default DetailedProjectPage;
