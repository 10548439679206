import React from 'react';
import { createTheme } from '@mui/material/styles';

export const homePageTheme = createTheme({
  palette: {
    primary: {
      main: '#9DCCE5',
    },
    secondary: {
      main: '#4886A7',
    },
    background: {
      default: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#E6F4FF',
    },
  },
});

export const projectsPageTheme = createTheme({
  palette: {
    primary: {
      main: '#9DCCE5',
    },
    secondary: {
      main: '#4886A7',
    },
    background: {
      default: '#000000',
    },
    text: {
      primary: '#ffffff',
      secondary: '#E6F4FF',
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        root: {
          '@media only screen and (max-width: 600px)': {
            marginTop: '10px',
          },
        },
      },
    },
  },
});

export const themeTwo = createTheme({
  palette: {
    primary: {
      main: '#009688',
    },
    secondary: {
      main: '#ffc107',
    },
    background: {
      default: '#e0f2f1',
    },
  },
});
