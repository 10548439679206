import React, { useEffect, useState } from 'react';

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { ImageData } from '../const/ImageData';
import { SERVER_URL } from '../const/MacroConst';

const InterestsListPage = () => {
  const { title } = useParams();
  const [images, setImages] = useState<ImageData[]>([]);
  const [cols, setCols] = useState(3); // Default columns
  const [windowWidth, setWindowWidth] = useState(0);
  const [rowHeight, setRowHeight] = useState(200); // Default row height

  useEffect(() => {
    // Fetch images from the backend API based on interest title
    fetch(`${SERVER_URL}/api/interest/${title}/`)
      .then((response) => response.json())
      .then((data) => {
        if (data.images) {
          // Manually define the sizes based on the pattern from the example image
          const imagesWithSizes = data.images.map(
            (img: ImageData, index: number) => {
              const predefinedSize = getPredefinedImageSize(index);
              return { ...img, ...predefinedSize };
            }
          );
          setImages(imagesWithSizes);
        }
      })
      .catch((error) => {
        console.error('Error fetching images:', error);
      });
  }, [title]);

  // Adjust the number of columns and row height based on window width
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth >= 1200) {
        setCols(6); // Larger screens (e.g., desktops)
        setRowHeight(250);
      } else if (window.innerWidth >= 800) {
        setCols(4); // Medium screens (e.g., tablets)
        setRowHeight(200);
      } else {
        setCols(2); // Small screens (e.g., mobile)
        setRowHeight(200);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the right values

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box sx={{ height: '100vh', padding: '2%' }}>
      <QuiltedImageList
        windowWidth={windowWidth}
        images={images}
        cols={cols}
        rowHeight={rowHeight}
      />
    </Box>
  );
};

const QuiltedImageList = ({
  windowWidth,
  images,
  cols,
  rowHeight,
}: {
  windowWidth: number;
  images: ImageData[];
  cols: number;
  rowHeight: number;
}) => {
  useEffect(() => {
    console.log('images ', images);
  }, [images]);

  return (
    <ImageList variant='quilted' cols={cols} rowHeight={rowHeight}>
      {images.map((img, index) => (
        <ImageListItem key={index} cols={img.cols || 1} rows={img.rows || 1}>
          <img
            data-aos='fade-zoom-in'
            data-aos-easing='ease-out-cubic'
            data-aos-duration='500'
            data-aos-offset='0'
            {...srcset(img.imgUrl, windowWidth, img.rows, img.cols)}
            loading='lazy'
          />
        </ImageListItem>
      ))}
    </ImageList>
  );
};

function srcset(image: string, size: number, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

// Manually set the rows and cols based on the pattern
function getPredefinedImageSize(index: number) {
  const pattern = [
    { rows: 2, cols: 2 }, // Large image
    { rows: 1, cols: 1 }, // Small image
    { rows: 1, cols: 1 }, // Small image
    { rows: 1, cols: 2 }, // Wide image
    { rows: 1, cols: 2 }, // Wide image
    { rows: 2, cols: 2 }, // Large image
    { rows: 1, cols: 1 }, // Small image
    { rows: 1, cols: 1 }, // Small image
  ];

  return pattern[index % pattern.length]; // Loop through the pattern
}

export default InterestsListPage;
