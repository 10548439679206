import React from 'react';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ContactPageRoundedIcon from '@mui/icons-material/ContactPageRounded';
import NoteRoundedIcon from '@mui/icons-material/NoteRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import InterestsRoundedIcon from '@mui/icons-material/InterestsRounded';
import ForumRoundedIcon from '@mui/icons-material/ForumRounded';

interface MenuItem {
  title: string;
  icon: JSX.Element;
  link: string;
}

export const menuItems: MenuItem[] = [
  {
    title: 'Home',
    icon: <HomeRoundedIcon color='primary' />,
    link: '/',
  },
  {
    title: 'Projects',
    icon: <AccountTreeRoundedIcon color='primary' />,
    link: '/projects',
  },
  {
    title: 'Interests',
    icon: <InterestsRoundedIcon color='primary' />,
    link: '/interests',
  },
  // {
  //   title: 'Blog',
  //   icon: <ForumRoundedIcon color='primary' />,
  //   link: '',
  // },
  // {
  //   title: 'Cheatsheet',
  //   icon: <NoteRoundedIcon color='primary' />,
  //   link: '',
  // },
  {
    title: 'Contact',
    icon: <ContactPageRoundedIcon color='primary' />,
    link: '/contacts',
  },
];
