import React, { useEffect, useState } from 'react';
import { Box, Grid, ThemeProvider } from '@mui/material';
import axios from 'axios';
import { SERVER_URL } from '../const/MacroConst';
import { ProjectItem } from '../const/ProjectItem';
import { ProjectCard } from '../components/projectsPage/ProjectCard';
import '../css/projectsPage/ProjectsPage.css';
import { Tag } from '../const/Tag';
import { projectsPageTheme } from '../themes/themes';
import { useLocation } from 'react-router-dom';

const ProjectsPage = ({
  searchQuery,
  setSearchQuery,
  activeTags,
  setActiveTags,
  handleClickTag,
}: {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
  activeTags: Tag[];
  setActiveTags: React.Dispatch<React.SetStateAction<Tag[]>>;
  handleClickTag: React.Dispatch<React.SetStateAction<Tag>>;
}) => {
  const [projects, setProjects] = useState<ProjectItem[]>([]);
  const location = useLocation();

  const fetchProjectData = (params?: URLSearchParams) => {
    axios
      .get(`${SERVER_URL}/api/projects/?${params ? params.toString() : ''}`)
      .then((response) => {
        console.log('response.data', response.data);
        setProjects(response.data);
      })
      .catch((error) => console.error('Error fetching projects:', error));
  };

  useEffect(() => {
    const params = new URLSearchParams({
      search: searchQuery,
      tags: activeTags.map((tag) => tag.name).join(','),
    });

    fetchProjectData(params);
  }, [searchQuery, activeTags]);

  // Reset searchQuery and activeTags when the Projects page is revisited
  useEffect(() => {
    if (location.pathname === '/projects') {
      setSearchQuery('');
      setActiveTags([]);
      fetchProjectData();
    }
  }, [location.pathname]);

  return (
    <ThemeProvider theme={projectsPageTheme}>
      <ProjectsContent projects={projects} addTag={handleClickTag} />
    </ThemeProvider>
  );
};

const ProjectsContent = ({
  projects,
  addTag,
}: {
  projects: ProjectItem[];
  addTag: (tag: Tag) => void;
}) => {
  return (
    <Box
      className='projects-container'
      sx={{
        // backgroundImage: 'url("/bg/homepage-1.png")',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          padding: '5%',
        }}
      >
        <Grid
          container
          spacing={{ xs: 3, md: 8 }}
          columns={{ xs: 3.5, sm: 8, md: 12 }}
          className='project-cards-container'
        >
          {projects.map((projectItem: ProjectItem) => (
            <Grid
              item
              xs={3}
              sm={4}
              md={4}
              key={`project-card-container-${projectItem.title}`}
              className='project-card-container'
            >
              <ProjectCard project={projectItem} addTag={addTag} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default ProjectsPage;
