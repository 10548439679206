import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ProjectsPage from './pages/ProjectsPage';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { PageWithNavBar } from './components/navBar/NavBar';
import ScrollToTop from './components/navBar/ScrollToTop';
import { Tag } from './const/Tag';
import DetailedProjectPage from './pages/DetailedProjectPage';
import InterestsPage from './pages/InterestsPage';
import InterestsListPage from './pages/InterestsListPage';
import ContactsPage from './pages/ContactsPage';

function App() {
  const [showMainContent, setShowMainContent] = useState(false);
  const [projectsPageSearchQuery, setProjectsPageSearchQuery] =
    useState<string>('');
  const [projectsActiveTags, setProjectsActiveTags] = useState<Tag[]>([]);

  const handleClickTag = (tag: Tag) => {
    setProjectsActiveTags((prev) => {
      const tagSet = new Set(prev);
      let isDelete = false;

      for (var prevTag of prev) {
        if (prevTag.name === tag.name) {
          tagSet.delete(prevTag);
          isDelete = true;
          break;
        }
      }

      if (!isDelete) tagSet.add(tag);
      return Array.from(tagSet);
    });
  };

  useEffect(() => {
    AOS.init({
      duration: 800,
      once: true,
    });
  }, []);

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route
          path='/'
          element={
            <HomePage show={showMainContent} setShow={setShowMainContent} />
          }
        />
        <Route
          path='/projects'
          element={
            <PageWithNavBar
              children={
                <ProjectsPage
                  searchQuery={projectsPageSearchQuery}
                  setSearchQuery={setProjectsPageSearchQuery}
                  activeTags={projectsActiveTags}
                  setActiveTags={setProjectsActiveTags}
                  handleClickTag={handleClickTag}
                />
              }
              setSearchTerm={setProjectsPageSearchQuery}
              activeTags={projectsActiveTags}
              removeTag={handleClickTag}
            />
          }
        />
        <Route
          path='/project/:title'
          element={
            <PageWithNavBar
              children={<DetailedProjectPage />}
              showSearchBar={false}
              showBackButton={true}
            />
          }
        />
        <Route
          path='/interests'
          element={
            <PageWithNavBar
              children={<InterestsPage />}
              showSearchBar={false}
            />
          }
        />
        <Route
          path='/interests/:title'
          element={
            <PageWithNavBar
              children={<InterestsListPage />}
              showSearchBar={false}
              showBackButton={true}
            />
          }
        />
        <Route
          path='/contacts'
          element={
            <PageWithNavBar children={<ContactsPage />} showSearchBar={false} />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
