import React, { useEffect, useRef } from 'react';
import '../../css/homePage/TypingIntro.css';
import { Box, Container, Grid } from '@mui/material';

export const TypingIntro = ({
  setShow,
}: {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const typingRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!typingRef || !typingRef.current) return;

    const typingIntro = typingRef.current;

    const handleAnimationEnd = () => {
      setTimeout(() => {
        setShow(true);
      }, 2500);
    };

    typingIntro.addEventListener('animationend', handleAnimationEnd);

    return () => {
      typingIntro.removeEventListener('animationend', handleAnimationEnd);
    };
  }, []);

  return (
    <Box className='typing-wrapper'>
      <div ref={typingRef} className='typing-intro'>
        Hello I'm Zixi Wang.
      </div>
    </Box>
  );
};
