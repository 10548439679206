import React, { useEffect, useRef } from 'react';
import '../../css/homePage/StarField.css';
import {
  createStar,
  interpolateAndCreate,
} from '../../utils/createCursorElement';

const StarField: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const lastPositionRef = useRef<{ x: number; y: number } | null>(null);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      const container = containerRef.current;
      if (container) {
        interpolateAndCreate(
          event.pageX,
          event.pageY,
          container,
          lastPositionRef
        );
      }
    };

    const handleTouchMove = (event: TouchEvent) => {
      const touch = event.touches[0];
      const container = containerRef.current;
      if (container) {
        interpolateAndCreate(
          touch.clientX,
          touch.clientY,
          container,
          lastPositionRef
        );
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  return <div ref={containerRef} id='star-container' />;
};

export default StarField;
