import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import { InterestItem } from '../../const/Interest';
import { Link } from 'react-router-dom';
import '../../css/interestsPage/InterestSlide.css';

export const InterestSlide = ({
  interest,
  index,
}: {
  interest: InterestItem;
  index: number;
}) => {
  const interestLink = `/interests/${interest.title
    .toLowerCase()
    .replace(/\s+/g, '-')}`;

  return (
    <Paper
      key={index}
      sx={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center', // Center the content inside the paper
        borderRadius: '0',
      }}
      className='paper'
    >
      <Box
        height='100%'
        width='100%'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignContent: 'center',
          alignItems: 'center',
          backgroundImage: `url(${interest.coverImage})`, // Set the background image
          backgroundSize: 'cover', // Cover the entire area
          backgroundPosition: 'center', // Center the image
          rowGap: '4%',
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h2'
          sx={{ textShadow: '2px 2px 4px #000000', fontWeight: 'bold' }}
          fontFamily='Georgia, serif'
        >
          {interest.title}
        </Typography>
        <Link to={interestLink} style={{ textDecoration: 'none' }}>
          <Typography
            variant='body1'
            component='div'
            fontFamily='URW Chancery L, cursive'
            color='primary'
            fontWeight='bold'
            className='interest-description'
          >
            {interest.description}
          </Typography>
        </Link>
      </Box>
    </Paper>
  );
};
