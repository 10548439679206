import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import { Box, Paper, ThemeProvider, Typography } from '@mui/material';
import { homePageTheme, projectsPageTheme } from '../themes/themes';
import { InterestSlide } from '../components/interestsPage/InterestSlide';
import { InterestItem } from '../const/Interest';
import { SERVER_URL } from '../const/MacroConst';

const InterestsPage = () => {
  const [interests, setInterests] = useState<InterestItem[]>([]);
  useEffect(() => {
    // Fetch the interests from the backend API
    fetch(`${SERVER_URL}/api/interests/`)
      .then((response) => response.json())
      .then((data) => {
        // Assuming the backend returns the correct interest data structure
        setInterests(data);
      })
      .catch((error) => {
        console.error('Error fetching interests:', error);
      });
  }, []);

  return (
    <ThemeProvider theme={projectsPageTheme}>
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          overflow: 'hidden', // Ensure no extra content spills
        }}
      >
        <Carousel
          interval={5000}
          stopAutoPlayOnHover={false}
          sx={{ height: '100vh' }} // Ensures full height
          className='carousel'
          indicatorIconButtonProps={{
            style: {
              fontSize: '30px', // Adjust the size of the indicator circles
            },
          }}
          indicatorContainerProps={{
            style: {
              zIndex: 1,
              marginTop: '-50px',
              position: 'relative',
            },
          }}
        >
          {interests.map((interest, index) => (
            <InterestSlide interest={interest} index={index} />
          ))}
        </Carousel>
      </Box>
    </ThemeProvider>
  );
};

export default InterestsPage;
