import React, { useState } from 'react';
import {
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { homePageTheme } from '../themes/themes';
import { CONTACT_FORM_URL } from '../const/MacroConst';
import '../css/contactsPage/contactsPage.css';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

const ContactsPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const handleChange = (e: any) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const response = await fetch(CONTACT_FORM_URL, {
      redirect: 'follow', // important, as google script sends two requests, the second one is a GET method. If keep using POST the server will error with 405.
      method: 'POST',
      headers: { 'Content-Type': 'text/plain;charset=utf-8' },
      body: JSON.stringify(formData),
    });
    const result = await response.json();
    if (result.status === 'success') {
      alert('Message sent successfully!');
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } else {
      alert('Failed to send message.');
    }
  };

  return (
    <ThemeProvider theme={homePageTheme}>
      <Box
        sx={{
          minHeight: '100vh',
          backgroundImage: 'url("/bg/homepage-1.png")',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Title />
        {/* Content */}
        <Grid
          container
          sx={{
            width: {
              xs: '90%',
              sm: '80%',
              md: '80%',
            },
            justifyContent: 'space-around', // Adjusts spacing between columns
          }}
          data-aos='fade-up'
          data-aos-easing='linear'
        >
          <ContactInfo />
          <ContactForm
            formData={formData}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

const Title = () => {
  return (
    <Typography
      variant='h2'
      sx={{
        fontFamily: 'Georgia, serif',
        fontWeight: 'bold',
        textShadow: '2px 2px 4px #000000',
        textAlign: 'center',
        mb: '4%',
        fontSize: {
          xs: '3rem', // Mobile screens
          sm: '3.5rem', // Tablet screens
          md: '4.5rem', // Larger screens
        },
        marginTop: {
          xs: '10%',
          sm: '8%',
        },
      }}
      color='textPrimary'
      data-aos='fade-down'
      data-aos-easing='linear'
    >
      Get in Touch
    </Typography>
  );
};

const ContactInfo = () => {
  const [isEmailHovered, setIsEmailHovered] = useState(false);
  const [isLinkedInHovered, setIsLinkedInHovered] = useState(false);

  const emailStyle = {
    textDecoration: 'none',
    color: isEmailHovered ? 'antiquewhite' : 'white', // Change color on hover
  };

  const linkedInStyle = {
    textDecoration: 'none',
    color: isLinkedInHovered ? 'antiquewhite' : 'white', // Change color on hover
  };

  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        variant='h5'
        sx={{
          textShadow: '2px 2px 4px #000000',
          fontWeight: 'bold',
          mb: '6%',
          fontFamily: 'Georgia, serif',
        }}
      >
        Contact Info
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <EmailRoundedIcon color='primary' />
          <Typography sx={{ fontFamily: 'Georgia, serif', mb: '3%', ml: '2%' }}>
            <a
              href='mailto:zixiwangcecilia@gmail.com'
              style={emailStyle}
              onMouseEnter={() => setIsEmailHovered(true)}
              onMouseLeave={() => setIsEmailHovered(false)}
            >
              zixiwangcecilia@gmail.com
            </a>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
          }}
        >
          <LinkedInIcon color='primary' />
          <Typography sx={{ fontFamily: 'Georgia, serif', mb: '3%', ml: '2%' }}>
            <a
              className='email-text'
              href='https://www.linkedin.com/in/zixi-wang-cecilia/'
              target='_blank'
              style={linkedInStyle}
              onMouseEnter={() => setIsLinkedInHovered(true)}
              onMouseLeave={() => setIsLinkedInHovered(false)}
            >
              www.linkedin.com/in/zixi-wang-cecilia/
            </a>
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          alignItems: 'center',
          mt: '10%',
        }}
      >
        <Box
          component='img'
          src='/img/wechat.jpg'
          alt='WeChat QR'
          sx={{
            height: '150px',
            width: '150px',
            borderRadius: '10px',
          }}
        />
        <Typography sx={{ fontFamily: 'Georgia, serif', mt: '3%' }}>
          Scan to connect via WeChat
        </Typography>
      </Box>
    </Grid>
  );
};

const ContactForm = ({
  formData,
  handleChange,
  handleSubmit,
}: {
  formData: any;
  handleChange: any;
  handleSubmit: any;
}) => {
  return (
    <Grid
      item
      xs={12}
      md={5}
      sx={{
        marginBottom: {
          xs: '10%',
          sm: '8%',
        },
        marginTop: {
          xs: '10%',
          sm: '5%',
          md: '0',
        },
      }}
    >
      <Box
        component='form'
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          bgcolor: 'rgba(255, 255, 255, 0.1)',
          padding: '4%',
          borderRadius: '10px',
          backdropFilter: 'blur(4px)',
          maxWidth: '400px', // Controls the form width
          margin: '0 auto', // Centers the form horizontally
        }}
        onSubmit={handleSubmit} // Added form submission handler here
      >
        <TextField
          label='Your Name'
          variant='outlined'
          fullWidth
          required
          name='name'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label='Your Email'
          variant='outlined'
          fullWidth
          required
          name='email'
          type='email'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label='Subject'
          variant='outlined'
          fullWidth
          required
          name='subject'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.subject}
          onChange={handleChange}
        />
        <TextField
          label='Message'
          variant='outlined'
          multiline
          rows={4}
          fullWidth
          required
          name='message'
          InputLabelProps={{
            style: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          InputProps={{
            sx: { color: 'white', fontFamily: 'Georgia, serif' },
          }}
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          type='submit'
          variant='contained'
          sx={{
            'backgroundColor': 'primary',
            'fontWeight': 'bold',
            '&:hover': {
              backgroundColor: '#e0e0e0',
            },
            'fontFamily': 'Georgia, serif',
          }}
        >
          Send Message
        </Button>
      </Box>
    </Grid>
  );
};

export default ContactsPage;
