import React, { useEffect, useState } from 'react';
import { styled, ThemeProvider, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { homePageTheme } from '../../themes/themes';
import { MenuItems } from './MenuItems';
import { Chip, Fab, Fade, InputBase, useScrollTrigger } from '@mui/material';
import { Tag } from '../../const/Tag';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import '../../css/navBar/NavBar.css';

const minDrawerWidth = 300;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  justifyContent: 'flex-end',
}));

const Search = styled('div')(({ theme }) => ({
  'position': 'relative',
  'borderRadius': theme.shape.borderRadius,
  'backgroundColor': alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  'marginLeft': 0,
  'width': '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  'color': 'text.secondary',
  'width': '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      'width': '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

const NavBar = ({
  open,
  showSearchBar,
  setSearchTerm,
  handleDrawerOpen,
  handleDrawerClose,
  menuItems,
  activeTags,
  removeTag,
}: {
  open: boolean;
  showSearchBar: boolean;
  setSearchTerm: (value: string) => void;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  menuItems: JSX.Element;
  activeTags: Tag[];
  removeTag: (tag: Tag) => void;
}) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target) return;
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <Toolbar
        sx={{
          position: 'fixed',
          zIndex: '100',
        }}
      >
        {!open && (
          <IconButton
            color='primary'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
          >
            <MenuIcon />
          </IconButton>
        )}
        {!open && showSearchBar && (
          <>
            <Search>
              <SearchIconWrapper>
                <SearchIcon color='primary' />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder='Search…'
                inputProps={{ 'aria-label': 'search' }}
                onChange={handleInputChange}
              />
            </Search>
            <Box className='remove-tags-container'>
              {activeTags.map((tag) => (
                <Chip
                  key={`remote-tag-${tag.name}`}
                  label={tag.name}
                  variant='outlined'
                  size='small'
                  sx={{
                    color: tag.color,
                    borderColor: tag.color,
                    fontSize: 'small',
                  }}
                  onDelete={() => removeTag(tag)}
                  deleteIcon={<CancelRoundedIcon color='primary' />}
                />
              ))}
            </Box>
          </>
        )}
      </Toolbar>
      <Drawer
        sx={{
          '& .MuiDrawer-paper': {
            width: `20%`,
            minWidth: `${minDrawerWidth}px`,
            backgroundColor: '#12273F',
          },
        }}
        anchor='left'
        open={open}
        onClose={handleDrawerClose}
        className='drawer-outmost'
      >
        <DrawerHeader>
          <IconButton
            color='primary'
            onClick={handleDrawerClose}
            sx={{ marginTop: '4%' }}
          >
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
          role='presentation'
          onClick={handleDrawerClose}
          className='drawer-box'
        >
          {menuItems}
        </Box>
      </Drawer>
    </>
  );
};

interface GoBackProps {
  window?: Window;
}

const GoBack = ({ window }: { window?: Window }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const handleScroll = () => {
      setVisible(true);
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => setVisible(false), 1500);
    };

    if (window) {
      window.addEventListener('scroll', handleScroll);

      return () => {
        window.removeEventListener('scroll', handleScroll);
        if (timer) clearTimeout(timer);
      };
    }
  }, [window]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    window?.history.back();
  };

  return (
    <Fade in={visible}>
      <Box
        onClick={handleClick}
        role='presentation'
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        <Fab
          size='small'
          aria-label='go back'
          sx={{ backgroundColor: 'rgb(18, 39, 63, 0.8)' }}
        >
          <ArrowBackRoundedIcon color='primary' />
        </Fab>
      </Box>
    </Fade>
  );
};

// If showNav bar is true, all params under that should be passed in
export const PageWithNavBar = ({
  children,
  showNavBar = true,
  showSearchBar = true,
  setSearchTerm = (value: string) => null,
  showBackButton = false,
  activeTags = [],
  removeTag = () => null,
}: {
  children: any;
  showNavBar?: boolean;
  showSearchBar?: boolean;
  setSearchTerm?: (value: string) => void;
  showBackButton?: boolean;
  activeTags?: Tag[];
  removeTag?: (tag: Tag) => void;
}) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div style={{ backgroundColor: 'black' }}>
      <ThemeProvider theme={homePageTheme}>
        <Box
          className='test'
          sx={{ display: 'flex', width: '100%', minWidth: '100vw' }}
        >
          {showNavBar && (
            <NavBar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
              menuItems={<MenuItems />}
              showSearchBar={showSearchBar}
              setSearchTerm={setSearchTerm}
              activeTags={activeTags}
              removeTag={removeTag}
            />
          )}
          <Box width='100vw' minHeight='100vh' id='outmost-box'>
            {children}
          </Box>
        </Box>
        {showBackButton && <GoBack window={window} />}
      </ThemeProvider>
    </div>
  );
};
