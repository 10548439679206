import React from 'react';

import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { menuItems } from '../../const/MenuItem';
import { Link } from 'react-router-dom';

export const MenuItems: () => JSX.Element = () => {
  return (
    <List>
      {menuItems.map((menuItem) => (
        <ListItem key={menuItem.title} disablePadding>
          <ListItemButton component={Link} to={menuItem.link}>
            <ListItemIcon sx={{ marginLeft: '20%' }}>
              {menuItem.icon}
            </ListItemIcon>
            <ListItemText primary={menuItem.title} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
};
